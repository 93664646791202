import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/startup.scss';

export class StartupPage extends React.Component{
    render(){
        return(
            <div className="startuppage">
                <div className="ui-split light">
                    <div className="content">
                        <h1>Light & Professionell</h1>
                        <Link to="/light">
                            <button className="btn">Visit</button>
                        </Link>
                    </div>
                </div>
                <div className="ui-split dark">
                    <div className="content">
                        <h1>Dark & Playfull</h1>
                        <Link to="/dark">
                            <button className="btn">Visit</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}