import React from 'react';
import '../../styles/dark/skills.scss';

export class SkillsContent extends React.Component{
    componentDidMount(){
        document.querySelector(".navbar .menu ul a:nth-child(3) li").classList.add("active");
        document.querySelector(".navbar .menu ul a:nth-child(3) li i").classList.add("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(3) li").classList.add("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(3) li i").classList.add("active");
    }

    componentWillUnmount(){
        document.querySelector(".navbar .menu ul a:nth-child(3) li").classList.remove("active");
        document.querySelector(".navbar .menu ul a:nth-child(3) li i").classList.remove("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(3) li").classList.remove("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(3) li i").classList.remove("active");
    }

    render(){
        return(
            <div className="content-skills">
                <div className="text">
                    <h1 className="text-headline">Skills & Technologies</h1>
                    <p className="text-normal">I'm familiar with OOP concepts and design patterns. I also have experience in the design and normalization of relational databases. Furthermore, I make use of a variety of frameworks and libraries depending on the job at hand which include but are not limited to fpdf, react-router and jQuery. I'm also experienced with SEO and WCAG best practices. Down below, you'll find an assortment of the programming languages I've worked with and how many years of experience I have in them.</p>
                </div>
                <Skills />
            </div>
        );
    }
}

class Skills extends React.Component{
    render(){
        return(
            <div className="skills">
                <SkillsItem icon="fab fa-html5" text={<p className="text-normal">HTML <br /> <b>4+</b> years of experience</p>} />
                <SkillsItem icon="fab fa-css3" text={<p className="text-normal">CSS <br /> <b>4+</b> years of experience</p>} />
                <SkillsItem icon="fab fa-sass" text={<p className="text-normal">SCSS <br /> <b>2+</b> years of experience</p>} />
                <SkillsItem icon="fab fa-php" text={<p className="text-normal">PHP <br /> <b>4+</b> years of experience</p>} />
                <SkillsItem icon="fab fa-js-square" text={<p className="text-normal">JS <br /> <b>4+</b> years of experience</p>} />
                <SkillsItem icon="fab fa-react" text={<p className="text-normal">React <br /> <b>2+</b> years of experience</p>} />
                <div className="clear" />
            </div>
        );
    }
}

class SkillsItem extends React.Component{
    constructor(props){
        super(props);

        this.item = React.createRef();
    }

    async animateSkill(){
        if(!this.item.current.classList.contains("active")){
            let list = document.querySelectorAll(".skills-item");
            list.forEach(item => {
                item.classList.remove("active");
            });
            this.item.current.classList.add("active");
        }else{
            this.item.current.classList.remove("active");
        }
    }

    render(){
        return(
            <div className="skills-item" onClick={() => this.animateSkill()} ref={this.item}>
                <svg width="140" height="160" viewBox="-1 -1 140 160">
                    <path d="m 66.56 0 l 66.56 38.4 l 0 76.8 l -66.56 38.4 l -66.56 -38.4 l 0 -76.8 z" stroke="#FF1493" strokeWidth="1" fill="transparent" />
                </svg>
                <div className="icon">
                    <i className={this.props.icon} />
                </div>
                <div className="text">
                    {this.props.text}
                </div>
            </div>
        );
    }
}