import React from 'react';
import '../../styles/dark/contact.scss';

export class ContactContent extends React.Component{
    constructor(props){
        super(props);

        this.name = React.createRef();
        this.email = React.createRef();
        this.subject = React.createRef();
        this.budget = React.createRef();
        this.message = React.createRef();
    }

    componentDidMount(){
        document.querySelector(".navbar .menu ul a:nth-child(4) li").classList.add("active");
        document.querySelector(".navbar .menu ul a:nth-child(4) li i").classList.add("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(4) li").classList.add("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(4) li i").classList.add("active");
    }

    componentWillUnmount(){
        document.querySelector(".navbar .menu ul a:nth-child(4) li").classList.remove("active");
        document.querySelector(".navbar .menu ul a:nth-child(4) li i").classList.remove("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(4) li").classList.remove("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(4) li i").classList.remove("active");
    }

    async sendMail(){
        let formData = new FormData();
        formData.append('name', this.name.current.value);
        formData.append('email', this.email.current.value);
        formData.append('subject', this.subject.current.value);
        formData.append('budget', this.budget.current.value);
        formData.append('message', this.message.current.value);

        const response = await fetch('./scripts/php/sendmail.php', {
            method: "POST",
            body: formData
        });

        const data = await response.text();

        if(data === "nice"){
            alert("Your email was send.");
        }else{
            console.log(data);
        }
    }

    render(){
        return(
            <div className="content-contact">
                <div className="text">
                    <h1 className="text-headline">Contact</h1>
                    <p className="text-normal">I'm looking for opportunities to collaborate with companies and individuals, not just work for them. If you want to get in touch or talk to me about a project collaboration you'd like me to be part of, fill out the form below and I'll get back to you as soon as possible.<br />You can also send me an e-mail to <a href="mailto:info@justint-dev.com">info@justint-dev.com.</a><br /><br /><span>Lets talk!</span></p>
                </div>

                <div className="form">
                    <div className="form-item">
                        <label className="text-normal">Name:</label>
                        <input className="input" placeholder="Name" ref={this.name} />
                    </div>
                    <div className="form-item">
                        <label className="text-normal">E-Mail:</label>
                        <input className="input" placeholder="E-Mail" ref={this.email} />
                    </div>
                    <div className="form-item">
                        <label className="text-normal">Subject:</label>
                        <input className="input" placeholder="Subject" ref={this.subject} />
                    </div>
                    <div className="form-item">
                        <label className="text-normal">Budget:</label>
                        <input className="input" placeholder="Budget" ref={this.budget} />
                    </div>
                    <div className="form-item-big">
                        <label className="text-normal">Message:</label>
                        <textarea className="input" rows="9" placeholder="Your Message" ref={this.message} />
                    </div>
                    <div className="form-buttons">
                        <button className="btn-main" onClick={() => this.sendMail()}>Submit</button>
                    </div>
                </div>
            </div>
        );
    }
}