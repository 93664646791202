import React from 'react';
import '../../styles/dark/about.scss'

export class AboutContent extends React.Component{
    componentDidMount(){
        document.querySelector(".navbar .menu ul a:nth-child(2) li").classList.add("active");
        document.querySelector(".navbar .menu ul a:nth-child(2) li i").classList.add("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(2) li").classList.add("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(2) li i").classList.add("active");
    }

    componentWillUnmount(){
        document.querySelector(".navbar .menu ul a:nth-child(2) li").classList.remove("active");
        document.querySelector(".navbar .menu ul a:nth-child(2) li i").classList.remove("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(2) li").classList.remove("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(2) li i").classList.remove("active");
    }

    render(){
        return(
            <div className="content-about">
                <div className="about-myself">
                    <h1 className="text-headline">Who I am</h1>
                    <p className="text-normal">I'm Justin, and I'm a Full-Stack Web developer from Germany. I first got into programming when I was a teenager, after high school I began to visit a vocational college for electrical engineering. During my time there, I realized that I would rather concentrate on the software side of things.<br /><br />In 2018, I began my certification as an IT specialist for application development in Germany, which I finished in 2021. The certificate was issued by the IHK (Industrie- und Handelskammer) in Berlin and is recognized by the German government.<br /><br />As part of my certification, I did a year-long internship at a German web development agency. During my time there I planned, designed and developed a variety of websites.<br /><br />Although the agency would have liked to keep me as an employee, I decided that I'd like to emigrate to another country. As part of this new beginning, I was determined to start my career as a freelance web developer.</p>
                </div>
                <div className="about-mywork">
                    <h1 className="text-headline">How I work</h1>
                    <p className="text-normal">Generally, every frontend I develop is 100% custom-made. I don't use Templates or bootstrap and similar frameworks unless the client specifically asks for it. My go-to tech stack is ReactJS, JSX and SASS for the frontend and an API written in PHP that interacts with a MySQL database for the backend. This can however change depending on the project, I  generally like to choose the best possible tools for the job. If necessary, I ship the websites I develop with a CMS in the background that is specifically tailored to the website's needs.</p>
                    <p className="text-normal">The typical commission will go as follows:</p>
                    <ul className="text-normal">
                        <li>You contact me via my website's contact form.</li>
                        <li>I'll get back to you, and we talk about the project's requirements.</li>
                        <li>You'll receive a quote from me and decide if you want to continue working together.</li>
                        <li>I plan the website in-depth, and you receive a design mock-up from me.</li>
                        <li>After we agreed on a design, I start developing the website, and you'll receive regular development updates.</li>
                        <li>I finish the development and testing of the website and hand over the code or deploy the website to your server.</li>
                    </ul>
                </div>
            </div>
        );
    }
}