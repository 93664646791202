import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import { StartupPage } from './sites/startup';
import { HomeLightPage } from './sites/light/home';
import { HomeDarkPage } from './sites/dark/home';
import './styles/shared.scss';

class App extends React.Component{
    render(){
        return(
            <div>
                <Router>
                    <Switch>
                        <Route path="/" component={HomeDarkPage} />
                    </Switch>
                </Router>
            </div>
        );
    }
}

{/* Add later
    <Router>
        <Switch>
            <Route path="/" exact component={StartupPage} />
            <Route path="/light" component={HomeLightPage} />
            <Route path="/dark" component={HomeDarkPage} />
        </Switch>
    </Router>
*/}

ReactDOM.render(
    <App />,
    document.getElementById('root')
);