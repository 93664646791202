import React from 'react';

export class HomeLightPage extends React.Component{
    render(){
        return(
            <div>
                
            </div>
        );
    }
}