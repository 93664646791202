import React from 'react';
import '../../styles/dark/portfolio.scss';

export class PortfolioContent extends React.Component{
    componentDidMount(){
        document.querySelector(".navbar .menu ul a:nth-child(4) li").classList.add("active");
        document.querySelector(".navbar .menu ul a:nth-child(4) li i").classList.add("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(4) li").classList.add("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(4) li i").classList.add("active");
    }

    componentWillUnmount(){
        document.querySelector(".navbar .menu ul a:nth-child(4) li").classList.remove("active");
        document.querySelector(".navbar .menu ul a:nth-child(4) li i").classList.remove("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(4) li").classList.remove("active");
        document.querySelector(".mobile-navbar .menu ul a:nth-child(4) li i").classList.remove("active");
    }

    render(){
        return(
            <div className="content-portfolio">
                <PortfolioItem img="./images/page.png" alt="just.dev" skills={["fab fa-html5", "fab fa-sass", "fab fa-js-square", "fab fa-react", "fab fa-php"]} />
                <PortfolioItem img="" alt="This could be your box!" skills={[]} />
                <PortfolioItem img="" alt="This could be your box!" skills={[]} />
                <PortfolioItem img="" alt="This could be your box!" skills={[]} />
                <PortfolioItem img="" alt="This could be your box!" skills={[]} />
                <PortfolioItem img="" alt="This could be your box!" skills={[]} />
                <PortfolioItem img="" alt="This could be your box!" skills={[]} />
                <PortfolioItem img="" alt="This could be your box!" skills={[]} />
                <PortfolioItem img="" alt="This could be your box!" skills={[]} />
            </div>
        );
    }
}

class PortfolioItem extends React.Component{
    constructor(props){
        super(props);
        
        this.item = React.createRef();
        this.overlay = React.createRef();
        this.overlayText = React.createRef();

        this.lock = false;
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    async animatePortfolio(){
        if(this.lock === false){
            if(!this.item.current.classList.contains("active")){
                this.lock = true;
                this.overlay.current.classList.remove("disable-transition");
                this.overlayText.current.innerHTML = this.props.alt;
    
                this.overlay.current.classList.remove("hidden");
                this.overlay.current.classList.add("active");
                await this.sleep(1000);
    
                let items = document.querySelectorAll(".portfolio-item");
                items.forEach((item) => {
                    item.classList.add("hidden");
                });
                this.item.current.classList.remove("hidden");
                this.item.current.classList.add("active");
    
                this.overlay.current.classList.remove("active");
                await this.sleep(500);
                this.overlay.current.classList.add("hidden");

                this.overlay.current.classList.add("disable-transition");
                this.lock = false;
            }else{
                this.lock = true;
                this.overlay.current.classList.remove("disable-transition");
                this.overlayText.current.innerHTML = "Overview";
    
                this.overlay.current.classList.remove("hidden");
                this.overlay.current.classList.add("active");
                await this.sleep(1000);
    
                let items = document.querySelectorAll(".portfolio-item");
                items.forEach((item) => {
                    item.classList.remove("hidden");
                });
                this.item.current.classList.remove("active");
    
                this.overlay.current.classList.remove("active");
                await this.sleep(500);
                this.overlay.current.classList.add("hidden");
                
                this.overlay.current.classList.add("disable-transition");
                this.lock = false;
            }
        }
    }

    render(){
        return(
            <div className="portfolio-item" onClick={() => this.animatePortfolio()} ref={this.item}>
                <div className="wrapper">
                    <img src={this.props.img} alt={this.props.alt} />

                    <div className="text-tech">
                        <h1 className="text-headline">Technologies used</h1>

                        <div className="skills">
                            {this.props.skills.map((item, i) =>
                                <div className="skills-item" key={i}>
                                    <svg width="100" height="100" viewBox="-1 -1 140 160">
                                        <path d="m 66.56 0 l 66.56 38.4 l 0 76.8 l -66.56 38.4 l -66.56 -38.4 l 0 -76.8 z" stroke="#FF1493" strokeWidth="1" fill="transparent" />
                                    </svg>
                                    <div className="icon">
                                        <i className={item} />
                                    </div>
                                    <div className="clear" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="text-desc">
                    <h1 className="text-headline">Project Details</h1>
                    <p className="text-normal">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce scelerisque ante vel nisi mollis, vitae egestas erat malesuada. In aliquam, purus a hendrerit blandit, nisl massa dignissim elit, ut facilisis lacus lectus at felis. Nam ullamcorper id tellus eget sodales. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla nec rhoncus mi, in consectetur felis. Etiam lobortis, metus ut dapibus feugiat, odio quam aliquam tellus, vel varius erat ex ac ipsum. Vestibulum ultrices pellentesque tristique. Morbi ornare eros eu vehicula tempor. Integer ut fringilla risus. Morbi quis sapien cursus, pulvinar lorem nec, congue nunc. Aliquam erat volutpat. Suspendisse in urna non sapien eleifend hendrerit sit amet id augue. Vestibulum in dapibus diam. Nullam nulla metus, euismod non aliquet non, cursus at metus. In consequat accumsan fermentum. Pellentesque tempor vestibulum nibh in gravida. Nunc a tortor non neque interdum convallis eu in tellus. Vestibulum placerat massa nec ligula vulputate fringilla. Nunc tincidunt urna enim, vitae consequat enim dictum ullamcorper. Aenean at ultrices quam. Phasellus vel nulla nisi. Pellentesque metus velit, consequat nec ornare sed, consequat ullamcorper mi. Fusce sollicitudin a libero ac tristique. Suspendisse eu odio lorem. In hac habitasse platea dictumst. Donec id lectus risus.</p>
                </div>

                <div className="overlay hidden" ref={this.overlay}>
                    <p className="text-headline" ref={this.overlayText}></p>
                </div>
            </div>
        );
    }
}